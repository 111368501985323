<template>
  <div class="wrapper bg-white">
    <div
      v-if="started && gameData.game_details"
      class="grid place-items-center pt-10"
    >
      <div class="flex relative">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/miror/games/logos/gtm.webp"
          alt="Miror - Guess the movie"
          width="295"
          height="85"
        />
      </div>
      <Counter
        class="relative my-4"
        :score="score"
        :displayTime="displayTime"
        :background="'darkgray'"
        :textcolor="'white'"
      />
      <div class="grid gap-2">
        <div
          class="bg-white border border-black rounded-2xl shadow-black-right"
        >
          <div class="emotes text-center">
            <h1 class="text-5xl py-4">
              {{ gameData.game_details.movies[currentSetIndex].emotes }}
            </h1>
          </div>
        </div>
        <div
          class="gtm-container rounded-2xl grid place-items-center content-start pt-2 relative"
        >
          <div
            id="movie1"
            @click.prevent="selectMovie('movie1')"
            :class="{ 'disable-click': isClickDisabled }"
            class="movie-box"
          >
            1.
            {{ gameData.game_details.movies[currentSetIndex].movie1 }}
          </div>
          <div
            id="movie2"
            @click.prevent="selectMovie('movie2')"
            :class="{ 'disable-click': isClickDisabled }"
            class="movie-box"
          >
            2.
            {{ gameData.game_details.movies[currentSetIndex].movie2 }}
          </div>
          <div
            id="movie3"
            @click.prevent="selectMovie('movie3')"
            :class="{ 'disable-click': isClickDisabled }"
            class="movie-box"
          >
            3.
            {{ gameData.game_details.movies[currentSetIndex].movie3 }}
          </div>
          <div
            id="movie4"
            @click.prevent="selectMovie('movie4')"
            :class="{ 'disable-click': isClickDisabled }"
            class="movie-box"
          >
            4.
            {{ gameData.game_details.movies[currentSetIndex].movie4 }}
          </div>
          <div class="text-nColorDarkGray font-InterExtraBold text-xl">
            <div class="correct hidden">Yay! you got it right!</div>
            <div class="incorrect hidden">Oops! you got that wrong!</div>
          </div>
        </div>
      </div>
    </div>
    <TimeElapsed ref="timeElapsedRef" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Counter from "@/components/mirorgames/elements/ScoreCounter.vue";
import TimeElapsed from "@/components/elements/TimeElapsed.vue";

export default {
  name: "GTM",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  components: { Counter, TimeElapsed },
  data() {
    return {
      started: false,
      totalIndex: 0,
      initialTime: {
        sec: 0,
        minute: 2,
      },
      displayTime: {
        sec: 0,
        minute: 2,
      },
      answerStartTime: {
        sec: 0,
        minute: 2,
      },
      totalTimeInMilliseconds: 120 * 1000,
      currentSetIndex: 0,
      score: 0,
      totalTimeTaken: 0,
      userAnswers: [],
      isClickDisabled: false,
    };
  },
  created() {
    this.totalIndex = this.gameData.game_details.movies.length;
    this.startGame();
  },
  mounted() {},
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    startGame() {
      this.started = true;
      this.countDown();
    },
    selectMovie(movie) {
      this.isClickDisabled = true;
      let answer = {
        set: this.gameData.game_details.movies[this.currentSetIndex].slug,
        answer: movie,
        time_taken: 0,
      };
      var timeTaken = this.calculateTimeTakenForAnswer();
      if (!timeTaken) {
        timeTaken = 1000;
      }
      if (
        this.gameData.game_details.movies[this.currentSetIndex]
          .correct_movie === movie
      ) {
        document.getElementById(movie).style.backgroundColor = "#9EC6AC";
        answer.is_correct = true;
        document.querySelector(".correct").classList.remove("hidden");
        this.score += 1;
        this.totalTimeTaken += timeTaken;
        answer.time_taken = timeTaken;
      } else {
        document.getElementById(movie).style.backgroundColor = "#ff74c7";
        answer.is_correct = false;
        document.querySelector(".incorrect").classList.remove("hidden");
        answer.time_taken = timeTaken;
      }
      this.userAnswers.push(answer);
      if (this.currentSetIndex + 1 === this.totalIndex) {
        this.submitScore();
      }
      setTimeout(() => {
        document.querySelector(".correct").classList.add("hidden");
        document.querySelector(".incorrect").classList.add("hidden");
        this.currentSetIndex += 1;
        document.getElementById(movie).style.backgroundColor = "#BD9EAE";
        this.isClickDisabled = false;
      }, 1000);
    },
    countDown() {
      this.timer = setInterval(() => {
        if (this.displayTime.minute == 0 && this.displayTime.sec == 0) {
          this.submitScore();
        } else {
          if (this.displayTime.sec == 0) {
            this.displayTime.sec = 59;
            this.displayTime.minute--;
          } else {
            this.displayTime.sec--;
          }
        }
      }, 1000);
    },
    submitScore() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      ApiService.post(apiResource.saveGTMScore, {
        business_slug: this.$route.params.business,
        time: this.totalTimeTaken.toString(),
        score: this.score.toString(),
        answers: this.userAnswers,
        schedule_id: this.gameData.schedule_id,
        game_session_id: this.gameData.game_session_id,
        timezone: timezone,
      })
        .then(() => {
          const url = new URL(window.location.href);
          url.searchParams.set("redirect", "gameover");
          url.searchParams.set(
            "game_session_id",
            this.gameData.game_session_id
          );
          window.location.href = url.toString();
        })
        .catch(() => {
          this.$refs.timeElapsedRef.startTimer();
        });
    },
    calculateTimeTakenForAnswer() {
      const diffMilliseconds =
        (this.answerStartTime.minute - this.displayTime.minute) * 60000 +
        (this.answerStartTime.sec - this.displayTime.sec) * 1000;
      this.answerStartTime = { ...this.displayTime };
      return diffMilliseconds;
    },
  },
};
</script>

<style scoped lang="postcss">
.wrapper {
  height: 100dvh;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.gtm-container {
  width: 20rem;
  height: 24rem;
}
.correct-bg {
  background-color: #9ec6ac;
}
.incorrect-bg {
  background-color: #ff74c7;
}
.gtm-movie {
  width: 20rem;
}
.emotes {
  max-width: 20rem;
}
@media (min-width: 410px) {
  .gtm-container {
    width: 22rem;
    height: 24rem;
  }
  .gtm-movie {
    width: 22rem;
  }
  .emotes {
    max-width: 22rem;
  }
}
.movie-box {
  @apply bg-mirorColors-lightPurple rounded-lg w-full text-black py-2.5 text-sm font-InterMedium focus:outline-none text-left px-4 mb-3;
}
.disable-click {
  pointer-events: none;
}
</style>
