<template>
  <div class="leaderboard bg-black overflow-scroll pb-20" v-if="leaderboard">
    <Header :to="'lobby'" />
    <div class="flex w-full justify-between pt-4 px-4 items-center">
      <p
        class="font-ZuumeSemiBold text-nColorYellow text-5xl text-shadow-black-right"
      >
        leaderboard
      </p>
      <div>
        <p
          class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
        >
          {{ formattedGamePlays }}
        </p>
        <p
          class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
          v-if="isEmptyObject(closedGame)"
        >
          {{ formattedLeaderboardCount }}
        </p>
      </div>
    </div>

    <div id="leaderboardContent" class="mt-2">
      <div v-if="!isEmptyObject(liveGame)">
        <div
          id="gameClosingTimer"
          class="flex justify-between items-center mt-1 font-InterBold text-nColorGreen border border-nColorGreen rounded-3xl mx-4 px-4 py-1 text-base"
        >
          <div>Game closes in:</div>
          <div class="flex items-center">
            <div class="w-10 text-center">
              <span id="gameClosingTimerD">00</span>
              <span class="text-sm ml-0.5">d</span>
            </div>
            <div class="w-10 text-center">
              <span id="gameClosingTimerH">00</span>
              <span class="text-sm ml-0.5">h</span>
            </div>
            <div class="w-10 text-center">
              <span id="gameClosingTimerM">00</span>
              <span class="text-sm ml-0.5">m</span>
            </div>
            <div class="w-10 text-center">
              <span id="gameClosingTimerS">00</span>
              <span class="text-sm ml-0.5">s</span>
            </div>
          </div>
        </div>
        <Results
          :leaderboard="liveGame.leaderboard"
          :gameLive="liveGame.game_live"
          class="px-4 pt-2"
        />
      </div>
      <div v-else-if="!isEmptyObject(leaderboardGame)" class="mt-2">
        <div
          id="leaderboardTimer"
          class="flex justify-between items-center mt-1 font-InterBold text-nColorGreen border border-nColorGreen rounded-3xl mx-4 px-4 py-1 text-base"
        >
          <div>Results in:</div>
          <div class="flex items-center">
            <div class="w-10 text-center">
              <span id="leaderboardTimerD">00</span>
              <span class="text-sm ml-0.5">d</span>
            </div>
            <div class="w-10 text-center">
              <span id="leaderboardTimerH">00</span>
              <span class="text-sm ml-0.5">h</span>
            </div>
            <div class="w-10 text-center">
              <span id="leaderboardTimerM">00</span>
              <span class="text-sm ml-0.5">m</span>
            </div>
            <div class="w-10 text-center">
              <span id="leaderboardTimerS">00</span>
              <span class="text-sm ml-0.5">s</span>
            </div>
          </div>
        </div>
        <Results
          :leaderboard="leaderboardGame.leaderboard"
          :gameLive="leaderboardGame.game_live"
          class="px-4 pt-2"
        />
      </div>
      <div v-else-if="!isEmptyObject(closedGame)" class="mt-2">
        <div
          id="leaderboardTimer"
          class="flex justify-center items-center mt-1 font-InterBold text-nColorLightGray border border-nColorLightGray rounded-3xl mx-4 px-4 py-1 text-lg"
        >
          <div>Game closed</div>
        </div>
        <Results
          :leaderboard="closedGame.leaderboard"
          :gameLive="closedGame.game_live"
          class="px-4 pt-2"
        />
      </div>
    </div>
    <div class="fixed bottom-0 w-full px-4 pb-4">
      <div class="w-full grid">
        <div
          class="shadow-border-orange-right bg-nColorYellow rounded-3xl w-full text-black text-center uppercase text-3xl font-ZuumeSemiBold focus:outline-none border border-black cursor-pointer"
          v-if="showPlayAgain"
        >
          <div
            class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
            @click.prevent="
              goToGame(liveGame.details.schedule_id, liveGame.meta.slug)
            "
          >
            <p v-if="liveGame.leaderboard.customer_results.has_played">
              Play Again
            </p>
            <p v-else>Play</p>
          </div>
        </div>
        <div
          class="shadow-border-orange-right bg-nColorYellow rounded-3xl w-full text-black text-center uppercase text-3xl font-ZuumeSemiBold focus:outline-none border border-black cursor-pointer"
          v-if="!isEmptyObject(closedGame) && !showClaim"
        >
          <div
            @click.once.prevent="goBackToLobby"
            class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
          >
            <p>Back to Lobby</p>
          </div>
        </div>
        <div
          class="shadow-border-orange-right bg-nColorYellow rounded-3xl w-full text-black text-center uppercase text-3xl font-ZuumeSemiBold focus:outline-none border border-black cursor-pointer"
          v-if="showClaim"
        >
          <router-link
            :to="{
              name: 'Rewards',
              params: {
                business: business,
              },
            }"
            class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
          >
            <p>Claim Reward</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/elements/Header.vue";
import Results from "@/components/Leaderboard/Results.vue";
import slugs from "@/common/slugs";
import confetti from "canvas-confetti";

export default {
  components: { Header, Results },
  data() {
    return {
      business: this.$route.params.business,
      leaderboard: null,
      gameCutoffTime: null,
      leaderboardTime: null,
      cutoffTimerStarted: false,
      cutoffIntervalId: null,
      leaderboardTimerStarted: false,
      leaderboardIntervalId: null,
      showPlayAgain: false,
      showClaim: false,
      flipText: "Claim Freebie",
      liveGame: {},
      leaderboardGame: {},
      closedGame: {},
      gamePlays: 0,
      leaderboardCount: 0,
      slugs,
    };
  },
  created() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    ApiService.get(apiResource.getLeaderboard, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
      timezone: timezone,
    }).then((data) => {
      this.leaderboard = data.data.data;
      this.gamePlays = data.data.data[0].total_gameplays;
      this.leaderboardCount = this.leaderboard[0].leaderboard.leaderboard_count;
      this.leaderboard.forEach((game) => {
        if (game.game_live) {
          this.showPlayAgain = true;
          this.showClaim = false;
          this.setGameCutoffDateTime(game.details.cutoff_datetime);
          this.startGameClosingTimer();
          this.liveGame = game;
        } else if (game.leaderboard_timer) {
          this.showPlayAgain = false;
          this.showClaim = false;
          this.setLeaderboardDateTime(game.details.leaderboard_datetime);
          this.startLeaderboardTimer();
          this.leaderboardGame = game;
        } else if (game.yet_to_start) {
          this.goBackToLobby();
        } else {
          this.showPlayAgain = false;
          this.showClaim = false;
          if (
            this.leaderboard[0].leaderboard.is_winner &&
            !this.leaderboard[0].leaderboard.claimed
          ) {
            this.showClaim = true;
          }
          this.closedGame = game;
        }
      });
    });
  },
  mounted() {
    setInterval(() => {
      this.flipText =
        this.flipText === "Click to Claim"
          ? "Congratulations!"
          : "Click to Claim";
    }, 1000);
  },
  beforeUnmount() {
    if (this.cutoffTimerStarted) {
      this.clearCutoffTimer();
    }
    if (this.leaderboardTimerStarted) {
      this.clearLeaderboardTimer();
    }
    clearInterval(this.gamePlaysIntervalId);
  },
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
    setGameCutoffDateTime(dateTime) {
      // Directly create the Date object from the ISO string
      this.gameCutoffTime = new Date(dateTime);

      // Check if the Date object is valid
      if (isNaN(this.gameCutoffTime.getTime())) {
        console.error(
          "Invalid Date: Ensure the input dateTime is in ISO format"
        );
      } else {
        console.log(this.gameCutoffTime); // Should now log a valid Date object
      }
    },
    setLeaderboardDateTime(dateTime) {
      // Directly create the Date object from the ISO string
      this.leaderboardTime = new Date(dateTime);

      // Check if the Date object is valid
      if (isNaN(this.leaderboardTime.getTime())) {
        console.error(
          "Invalid Date: Ensure the input dateTime is in ISO format"
        );
      } else {
        console.log(this.leaderboardTime); // Should now log a valid Date object
      }
    },
    getTimeCalculation() {
      const currentTime = new Date();
      const remainingTime = Math.max(0, this.gameCutoffTime - currentTime);

      const days = Math.floor(
        remainingTime / (1000 * 60 * 60 * 24)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    getLeaderboardTimeCalculation() {
      const currentTime = new Date();
      const remainingTime = Math.max(0, this.leaderboardTime - currentTime);

      const days = Math.floor(
        remainingTime / (1000 * 60 * 60 * 24)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    startGameClosingTimer() {
      setTimeout(() => {
        const timerElementD = document.getElementById("gameClosingTimerD");
        const timerElementH = document.getElementById("gameClosingTimerH");
        const timerElementM = document.getElementById("gameClosingTimerM");
        const timerElementS = document.getElementById("gameClosingTimerS");
        this.cutoffTimerStarted = true;
        this.cutoffIntervalId = setInterval(() => {
          const data = this.getTimeCalculation();
          timerElementD.innerHTML = data.days;
          timerElementH.innerHTML = data.hours;
          timerElementM.innerHTML = data.minutes;
          timerElementS.innerHTML = data.seconds;
          if (data.remainingTime <= 0) {
            window.location.reload();
          }
        }, 1000);
      }, 500);
    },
    startLeaderboardTimer() {
      setTimeout(() => {
        const timerElementD = document.getElementById("leaderboardTimerD");
        const timerElementH = document.getElementById("leaderboardTimerH");
        const timerElementM = document.getElementById("leaderboardTimerM");
        const timerElementS = document.getElementById("leaderboardTimerS");
        this.leaderboardTimerStarted = true;
        this.leaderboardIntervalId = setInterval(() => {
          const data = this.getLeaderboardTimeCalculation();
          timerElementD.innerHTML = data.days;
          timerElementH.innerHTML = data.hours;
          timerElementM.innerHTML = data.minutes;
          timerElementS.innerHTML = data.seconds;
          if (data.remainingTime <= 0) {
            window.location.reload();
          }
        }, 1000);
      }, 500);
    },
    clearCutoffTimer() {
      clearInterval(this.cutoffIntervalId);
      this.cutoffTimerStarted = false;
    },
    clearLeaderboardTimer() {
      clearInterval(this.leaderboardIntervalId);
      this.leaderboardTimerStarted = false;
    },
    goBackToLobby() {
      this.$router.push({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    reloadPage() {
      window.location.reload();
    },
    goToGame(scheduleId, game) {
      this.$router.push({
        name: "Game",
        params: {
          business: this.$route.params.business,
          schedule_id: scheduleId,
          game: game,
        },
      });
    },
    claimFreebie(scheduleId) {
      this.showConfetti();
      var parameters = {
        business: this.$route.params.business,
        schedule_id: scheduleId,
      };
      setTimeout(() => {
        let category = this.$store.getters.businessCategory;
        if (category === "bpcl") {
          this.$router.push({
            name: "TableNumber",
            params: parameters,
          });
        } else if (category === "event") {
          /*this.$router.push({
            name: "Freebie",
            params: parameters,
          });*/
          this.$router.push({
            name: "Rewards",
            params: {
              business: this.$route.params.business,
            },
          });
        }
      }, 2000);
    },
    showConfetti() {
      // Configure the confetti options
      const confettiOptions = {
        particleCount: 1500,
        spread: 220,
        origin: { y: 0.7 },
      };

      // Trigger the confetti effect
      confetti(confettiOptions);

      // Stop the confetti after 1 second
      setTimeout(() => {
        confetti.reset();
      }, 2000);
    },
    reverseArray(array) {
      if (Array.isArray(array)) {
        return array.slice().reverse();
      } else {
        return array;
      }
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    formatFriendlyTime(timeString) {
      const [hour, minute] = timeString.split(":");
      let period = "AM";

      let hourNum = parseInt(hour, 10);
      if (hourNum >= 12) {
        period = "PM";
        if (hourNum > 12) {
          hourNum -= 12;
        }
      }

      return `${hourNum}:${minute} ${period}`;
    },
    showTrophy(position, game) {
      if (position <= parseInt(game.details.no_of_winners)) {
        return true;
      }
      return false;
    },
  },
  computed: {
    formattedGamePlays() {
      // Format the number with "k" suffix if it's in the thousands
      if (this.gamePlays >= 1000) {
        return (this.gamePlays / 1000).toFixed(2) + "k Game Plays";
      } else {
        return this.gamePlays + " Game Plays";
      }
    },
    formattedLeaderboardCount() {
      if (this.leaderboardCount >= 1000) {
        return (this.leaderboardCount / 1000).toFixed(2) + "k Playing";
      } else {
        return this.leaderboardCount + " Playing";
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.leaderboard {
  height: 100dvh;
}
@keyframes sparkle {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.sparkle-button {
  display: inline-block;
  animation: sparkle 0.75s infinite;
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0deg);
  }
  50% {
    transform: perspective(400px) rotateY(90deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
  }
}

#flip-text {
  animation: flip 2s infinite;
}
.normal-row {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  padding: 8px;
  text-align: left;
}
.highlighted-row {
  @apply bg-nColorYellow;
  padding: 8px;
}
.highlighted-row td {
  padding: 8px;
}
.you-text {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  color: black;
}
</style>
