<template>
  <div>
    <div
      v-if="started && gameData.game_details"
      class="wrapper bg-white grid content-start w-full items-start pt-6 place-items-center"
    >
      <div class="flex relative">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/miror/games/logos/what-came-first.webp"
          alt="Miror - What came first"
          width="295"
          height="85"
        />
      </div>
      <Counter class="mt-4" :score="score" :displayTime="displayTime" />
      <div class="flex gap-2">
        <div
          class="wcf-container grid place-items-center content-start pt-4 relative"
          v-if="gameData.game_details.whatcamefirsts[currentSetIndex]"
        >
          <div
            :class="{ 'disable-click': isClickDisabled }"
            class="w-full grid place-items-center relative"
            @click="selectOption('option1')"
          >
            <img
              :src="
                gameData.game_details.whatcamefirsts[currentSetIndex]
                  .option1_image
              "
              width="271"
              height="180"
              class="border border-black rounded-lg image-shadow-right"
            />
            <div
              id="option1Overlay"
              class="absolute top-0 left-0 bg-black bg-opacity-70 w-full h-full hidden rounded-lg"
            >
              <div class="flex w-full h-full items-center content-center">
                <lottie-animation
                  id="option1Correct"
                  path="lotties/answer-correct.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
                <lottie-animation
                  id="option1Wrong"
                  path="lotties/answer-wrong.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
              </div>
            </div>
            <div
              id="option1Text"
              class="absolute -bottom-4 left-1/2 transform -translate-x-1/2 bg-mirorColors-lightPurple border border-black px-2 py-0.5 text-black font-MontserratSemiBold rounded-lg text-center"
            >
              {{
                gameData.game_details.whatcamefirsts[currentSetIndex].option1
              }}
            </div>
          </div>
          <div class="text-black font-MontserratBold text-3xl py-6">OR</div>
          <div
            :class="{ 'disable-click': isClickDisabled }"
            class="w-full grid place-items-center relative"
            @click="selectOption('option2')"
            v-if="gameData.game_details.whatcamefirsts[currentSetIndex]"
          >
            <img
              :src="
                gameData.game_details.whatcamefirsts[currentSetIndex]
                  .option2_image
              "
              width="271"
              height="180"
              class="border border-black rounded-lg image-shadow-left"
            />
            <div
              id="option2Overlay"
              class="absolute top-0 left-0 bg-black bg-opacity-70 w-full h-full hidden rounded-lg"
            >
              <div class="flex w-full h-full items-center content-center">
                <lottie-animation
                  id="option2Correct"
                  path="lotties/answer-correct.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
                <lottie-animation
                  id="option2Wrong"
                  path="lotties/answer-wrong.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
              </div>
            </div>
            <div
              id="option2Text"
              class="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-mirorColors-lightPurple border border-black px-2 py-0.5 text-black font-MontserratSemiBold rounded-lg text-center"
            >
              {{
                gameData.game_details.whatcamefirsts[currentSetIndex].option2
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TimeElapsed ref="timeElapsedRef" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Counter from "@/components/mirorgames/elements/ScoreCounter.vue";
import TimeElapsed from "@/components/elements/TimeElapsed.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "WhatCameFirst",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  components: { Counter, TimeElapsed, LottieAnimation },
  data() {
    return {
      started: false,
      totalIndex: 0,
      initialTime: {
        sec: 30,
        minute: 1,
      },
      displayTime: {
        sec: 30,
        minute: 1,
      },
      answerStartTime: {
        sec: 30,
        minute: 1,
      },
      totalTimeInMilliseconds: 90 * 1000,
      currentSetIndex: 0,
      score: 0,
      totalTimeTaken: 0,
      userAnswers: [],
      isClickDisabled: false,
      noGameSeconds: 0,
    };
  },
  created() {
    this.totalIndex = this.gameData.game_details.whatcamefirsts.length;
    this.startGame();
  },
  mounted() {},
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    startGame() {
      this.started = true;
      this.countDown();
    },
    selectOption(option) {
      this.isClickDisabled = true;
      let answer = {
        set: this.gameData.game_details.whatcamefirsts[this.currentSetIndex]
          .slug,
        answer: option,
        time_taken: 0,
      };
      document.querySelector("#option1Overlay").classList.remove("hidden");
      document.querySelector("#option2Overlay").classList.remove("hidden");
      document.querySelector("#option1Text").innerHTML =
        this.gameData.game_details.whatcamefirsts[
          this.currentSetIndex
        ].option1_year;
      document.querySelector("#option2Text").innerHTML =
        this.gameData.game_details.whatcamefirsts[
          this.currentSetIndex
        ].option2_year;
      var timeTaken = this.calculateTimeTakenForAnswer();
      if (!timeTaken) {
        timeTaken = 1000;
      }
      if (
        this.gameData.game_details.whatcamefirsts[this.currentSetIndex]
          .correct_answer === option
      ) {
        answer.is_correct = true;
        this.score += 1;
        document
          .querySelector("#" + option + "Correct")
          .classList.remove("hidden");
        this.totalTimeTaken += timeTaken;
        answer.time_taken = timeTaken;
      } else {
        answer.is_correct = false;
        document
          .querySelector("#" + option + "Wrong")
          .classList.remove("hidden");
        answer.time_taken = timeTaken;
      }
      this.userAnswers.push(answer);
      if (this.currentSetIndex + 1 === this.totalIndex) {
        this.submitScore();
      }
      setTimeout(() => {
        this.currentSetIndex += 1;
        document.querySelector("#option1Overlay").classList.add("hidden");
        document.querySelector("#option2Overlay").classList.add("hidden");
        document.querySelector("#option1Correct").classList.add("hidden");
        document.querySelector("#option1Wrong").classList.add("hidden");
        document.querySelector("#option2Correct").classList.add("hidden");
        document.querySelector("#option2Wrong").classList.add("hidden");
        this.isClickDisabled = false;
      }, 1000);
    },
    countDown() {
      this.timer = setInterval(() => {
        if (this.displayTime.minute == 0 && this.displayTime.sec == 0) {
          this.submitScore();
        } else {
          if (this.displayTime.sec == 0) {
            this.displayTime.sec = 59;
            this.displayTime.minute--;
          } else {
            this.displayTime.sec--;
          }
        }
      }, 1000);
    },
    submitScore() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      ApiService.post(apiResource.saveWhatCameFirstScore, {
        business_slug: this.$route.params.business,
        time: this.totalTimeTaken.toString(),
        score: this.score.toString(),
        answers: this.userAnswers,
        schedule_id: this.gameData.schedule_id,
        game_session_id: this.gameData.game_session_id,
        timezone: timezone,
      })
        .then(() => {
          const url = new URL(window.location.href);
          url.searchParams.set("redirect", "gameover");
          url.searchParams.set(
            "game_session_id",
            this.gameData.game_session_id
          );
          window.location.href = url.toString();
        })
        .catch(() => {
          this.$refs.timeElapsedRef.startTimer();
        });
    },
    calculateTimeTakenForAnswer() {
      const diffMilliseconds =
        (this.answerStartTime.minute - this.displayTime.minute) * 60000 +
        (this.answerStartTime.sec - this.displayTime.sec) * 1000;
      this.answerStartTime = { ...this.displayTime };
      return diffMilliseconds;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100dvh;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.correct-bg {
  background-color: #daf559;
}
.incorrect-bg {
  background-color: #ff74c7;
}
.image-shadow-left {
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.8);
}
.image-shadow-right {
  box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.8);
}
.disable-click {
  pointer-events: none;
}
.blurry-div {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
