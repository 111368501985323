<template>
  <div class="wrapper" v-if="lobbyDetails">
    <Header
      :to="'leaderboard'"
      v-if="lobbyDetails.game_status != null && lobbyDetails.games.length != 0"
    />
    <GameJamNotStarted
      :gameJamId="lobbyDetails.game_jam_id"
      v-if="!lobbyDetails.game_status && lobbyDetails.game_jam_type === 'diy'"
    />
    <NoExpsToday
      v-else-if="
        lobbyDetails.game_status === null && lobbyDetails.games.length === 0
      "
    />
    <div class="bg-black" v-else>
      <div class="flex flex-col place-items-center mt-4" v-if="lobbyDetails">
        <div
          class="relative w-full px-6 text-white"
          v-if="lobbyDetails.game_status === 'closed'"
        >
          <div class="flex justify-center">
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/gamer/game-jam-completed.webp"
              width="200"
              height="88"
            />
          </div>
          <div class="grid justify-items-center">
            <p class="text-white font-InterSemiBold text-xl">
              Game Jam Completed!
            </p>
            <p class="text-nColorLightGray font-InterSemiBold text-center">
              Select a game you have played below to check where you stand.
            </p>
          </div>
        </div>
        <div class="relative w-full px-6" v-else>
          <div class="">
            <div id="gameTimerWrapper">
              <div
                class="text-white font-InterSemiBold text-xl text-center pb-2"
              >
                <p v-if="lobbyDetails.game_status === 'next'">
                  Games go live in
                </p>
                <p v-else-if="lobbyDetails.game_status === 'live'">
                  Games end in
                </p>
                <p v-else-if="lobbyDetails.game_status === 'complete'">
                  Results announced in
                </p>
              </div>
              <div
                :class="{
                  'bg-nColorOrange': lobbyDetails.game_status === 'next',
                  'bg-nColorBannerGreen': lobbyDetails.game_status === 'live',
                  'bg-nColorTimerBlue': lobbyDetails.game_status === 'complete',
                }"
                class="flex justify-center w-full rounded-xl triple-border py-1"
              >
                <span
                  id="gameTimer"
                  class="font-ZuumeSemiBold text-3xl text-black"
                  >00<span class="font-ZuumeSemiBold text-base"> days </span
                  >00<span class="font-ZuumeSemiBold text-base"> hours </span
                  >00<span class="font-ZuumeSemiBold text-base"> mins </span
                  >00<span class="font-ZuumeSemiBold text-base">
                    secs</span
                  ></span
                >
              </div>
              <p
                class="font-InterExtraBold text-xs text-center mt-2 text-nColorLightGray"
                v-if="
                  lobbyDetails.game_status === 'next' ||
                  lobbyDetails.game_status === 'live'
                "
              >
                Play multiple times. Highest score is considered.
              </p>
            </div>
          </div>
        </div>
        <div class="w-full px-4 my-6" v-if="lobbyDetails.has_category">
          <div class="grid grid-cols-2 gap-2" v-if="!showGames">
            <div
              v-for="cat in lobbyDetails.games"
              :key="cat"
              @click="showGamesForCategory(cat)"
            >
              <div>
                <img :src="cat.category_icon" />
              </div>
            </div>
          </div>
          <div class="w-full" v-if="showGames">
            <div class="flex items-center w-full py-2">
              <div class="w-1/12">
                <img
                  :src="require('@/assets/images/navigate-back.svg')"
                  @click="hideGamesAndShowCategories()"
                />
              </div>
              <div
                class="w-10/12 text-nColorYellow font-ZuumeBold text-4xl text-shadow-orange-right-small text-center"
              >
                {{ selectedCategory.category_name }}
              </div>
            </div>
            <div
              v-for="game in selectedCategoryGames"
              :key="game"
              :class="{
                'bg-black text-nColorYellow border border-nColorYellow':
                  game.status === 'next',
                'bg-nColorYellow border border-black':
                  game.status === 'live' ||
                  game.status === 'complete' ||
                  lobbyDetails.game_status === 'closed',
              }"
              class="mb-2 p-2 rounded-2xl shadow-orange-right"
              @click.prevent="
                (game.status === 'live' ||
                  game.status === 'complete' ||
                  lobbyDetails.game_status === 'closed') &&
                  goToGame(game.slug, game.schedule_id)
              "
            >
              <div class="flex justify-between items-center">
                <div class="flex gap-2 items-center">
                  <div class="flex justify-center items-center">
                    <img :src="game.icon" class="w-10" />
                  </div>
                  <div class="font-ZuumeSemiBold text-xl">
                    {{ game.name }}
                  </div>
                </div>
                <div v-if="lobbyDetails.game_status === 'live'">
                  <img
                    :src="require('@/assets/images/right-arrow.svg')"
                    class="w-4 h-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 my-6" v-else>
          <div v-if="lobbyDetails">
            <div
              :class="{
                'grid-cols-1': gamesToDisplay.length === 1,
                'grid-cols-2': gamesToDisplay.length > 1,
              }"
              class="grid gap-4 justify-items-center"
            >
              <div
                v-for="game in gamesToDisplay"
                :key="game.schedule_id"
                @click.prevent="
                  game.status === 'closed'
                    ? goToLeaderboard(game.schedule_id)
                    : game.status !== 'next' &&
                      goToGame(game.slug, game.schedule_id)
                "
                class="relative"
              >
                <div
                  v-if="game.showStatus"
                  :class="{
                    'text-nColorYellow': game.status === 'next',
                    'text-nColorGreen': game.status === 'live',
                    'text-nColorGameOverBlue': game.status === 'complete',
                    'text-nColorOrange': game.status === 'closed',
                  }"
                  class="absolute -top-1.5 left-2 bg-nColorDarkGray rounded-3xl px-3 py-0.5 font-InterBold text-xs capitalize z-20"
                >
                  {{ game.status }}
                </div>
                <div
                  v-if="game.status === 'next'"
                  class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10"
                ></div>
                <img
                  :src="game.tile"
                  :class="{
                    'w-1/2': gamesToDisplay.length === 1,
                    'w-full': gamesToDisplay.length > 1,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            lobbyDetails &&
            lobbyDetails.games &&
            rewardPartners &&
            rewardPartners.length > 0
          "
        >
          <p
            class="text-white font-InterSemiBold text-sm px-4 py-2 mt-2 text-center"
          >
            Reward Partners
          </p>
          <div
            :class="{
              'flex justify-center': rewardPartners.length === 1,
              'grid grid-cols-2 gap-3 px-4 justify-center':
                rewardPartners.length === 2,
              'grid grid-cols-3 gap-3 px-4 justify-center':
                rewardPartners.length === 3,
              'grid grid-cols-4 gap-3 px-4 justify-center':
                rewardPartners.length > 3,
            }"
            class="mb-8"
          >
            <div
              v-for="partner in rewardPartners"
              :key="partner"
              class="flex justify-center mt-1"
            >
              <img
                :src="partnerImage(partner)"
                alt="Reward Partners"
                class="w-20 h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/elements/Header.vue";
import GameJamNotStarted from "@/components/emptystates/GameJamNotStarted.vue";
import TokenService from "@/common/token.service";
import NoExpsToday from "@/components/emptystates/NoExpsToday.vue";

export default {
  name: "Lobby",
  components: { GameJamNotStarted, Header, NoExpsToday },
  data() {
    return {
      businessSlug: this.$store.getters.businessSlug,
      businessSrc: this.$store.getters.businessSrc,
      timer: null,
      timerStarted: false,
      timerIntervalId: null,
      showGames: false,
      selectedCategory: null,
      selectedCategoryGames: [],
      rewardPartners: [],
      lobbyDetails: null,
      game: null,
      hasDisplayedNext: false,
    };
  },
  created() {
    const tokenValue = TokenService.getToken("diy_access");

    if (tokenValue === "false") {
      console.log(TokenService.getToken("diy_access"));
      this.$router.push({
        name: "UserLimit",
        params: {
          business: this.$route.params.business,
        },
      });
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    ApiService.get(apiResource.getLobbyDetails, {
      business_slug: this.$route.params.business,
      timezone: timezone,
    }).then((data) => {
      this.lobbyDetails = data.data.data;

      if (this.lobbyDetails.timer) {
        this.setDateTime(this.lobbyDetails.timer);
      }
      this.rewardPartners = this.lobbyDetails.reward_partners;
    });
  },
  mounted() {
    setTimeout(() => {
      if (this.timer) {
        this.setTimer("gameTimer");
      }
      // var webPushrBell = document.getElementById("webpushr-bell-optin");
      // if (webPushrBell) {
      //   webPushrBell.style.display = "block";
      // }
      // var webPushrPrompt = document.getElementById("webpushr-prompt-wrapper");
      // if (webPushrPrompt) {
      //   webPushrPrompt.style.display = "block";
      // }
      // var vue = this;
      // if (typeof window.webpushr === "function") {
      //   window.webpushr("fetch_id", function (sid) {
      //     if (sid) {
      //       ApiService.post(apiResource.saveWebPushSid, {
      //         web_push_sid: sid,
      //         business_slug: vue.$route.params.business,
      //       });
      //     }
      //   });
      // }
    }, 1000);
  },
  // beforeRouteLeave(to, from, next) {
  //   var webPushrBell = document.getElementById("webpushr-bell-optin");
  //   var webPushrPrompt = document.getElementById("webpushr-prompt-wrapper");
  //   if (webPushrBell) {
  //     document.getElementById("webpushr-bell-optin").style.display = "none";
  //   }
  //   if (webPushrPrompt) {
  //     document.getElementById("webpushr-prompt-wrapper").style.display = "none";
  //   }
  //   next();
  // },
  beforeUnmount() {
    clearInterval(this.timerIntervalId);
    this.timerStarted = false;
  },
  computed: {
    gridClass() {
      return this.lobbyDetails.details.reward_partners === 1
        ? "grid place-items-center"
        : "grid grid-cols-3";
    },
    gamesToDisplay() {
      // Returns an array of games to display
      return this.lobbyDetails.games.map((game) => {
        if (
          game.status === "next" &&
          game.grouped_games === false &&
          !this.hasDisplayedNext
        ) {
          this.hasDisplayedNext = true; // Set the flag when the first 'next' status is displayed
          return { ...game, showStatus: true }; // Indicate to show this status
        } else if (game.status !== "next") {
          return { ...game, showStatus: true }; // Always show other statuses
        } else {
          return { ...game, showStatus: false }; // Don't show subsequent 'next' statuses
        }
      });
    },
  },
  methods: {
    showGamesForCategory(category) {
      this.selectedCategory = category;
      this.setGamesForCategory(category);
      this.showGames = true;
    },
    hideGamesAndShowCategories() {
      this.selectedCategory = null;
      this.selectedCategoryGames = null;
      this.showGames = false;
    },
    setGamesForCategory(category) {
      for (const cat of this.lobbyDetails.games) {
        if (cat.category_slug === category.category_slug) {
          this.selectedCategoryGames = cat.games;
          return;
        }
      }
      return;
    },
    setTimer(timerElementName) {
      const timerElement = document.getElementById(timerElementName);

      this.timerIntervalId = setInterval(() => {
        const data = this.getTimeCalculation();

        const dayLabel = data.days === "01" ? "day" : "days";
        const hourLabel = data.hours === "01" ? "hour" : "hours";
        const minuteLabel = data.minutes === "01" ? "min" : "mins";
        const secondLabel = data.seconds === "01" ? "sec" : "secs";

        timerElement.innerHTML = `
      ${data.days}<span class="font-ZuumeSemiBold text-base"> ${dayLabel} </span>
      ${data.hours}<span class="font-ZuumeSemiBold text-base"> ${hourLabel} </span>
      ${data.minutes}<span class="font-ZuumeSemiBold text-base"> ${minuteLabel} </span>
      ${data.seconds}<span class="font-ZuumeSemiBold text-base"> ${secondLabel}</span>
    `;

        if (data.remainingTime <= 0) {
          clearInterval(this.timerIntervalId); // Fix to clear interval correctly
          window.location.reload();
        }
      }, 1000);
    },
    setDateTime(dateTime) {
      // Replace "+00:00" with "Z" (Z indicates UTC in ISO 8601)
      const standardizedDateTime = dateTime.replace("+00:00", "Z");

      // Create the Date object
      this.timer = new Date(standardizedDateTime);

      // Add a 3-second delay
      this.timer.setSeconds(this.timer.getSeconds() + 3);
    },
    getTimeCalculation() {
      var dateTimeToConsider = this.timer;
      const currentTime = new Date();
      const remainingTime = Math.max(0, dateTimeToConsider - currentTime);

      const days = Math.floor(
        remainingTime / (1000 * 60 * 60 * 24)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    goToGame(game, scheduleId) {
      this.$router.push({
        name: "Game",
        params: {
          business: this.$route.params.business,
          schedule_id: scheduleId,
          game: game,
        },
      });
    },
    goToLeaderboard(scheduleId) {
      this.$router.push({
        name: "Leaderboard",
        params: {
          business: this.$route.params.business,
          schedule_id: scheduleId,
        },
      });
    },
    formattedTime(timeToFormat) {
      const [hours, minutes] = timeToFormat.split(":");
      const formattedHours = hours % 12 || 12;
      const period = hours < 12 ? "AM" : "PM";
      return `${formattedHours}:${minutes} ${period}`;
    },
    partnerImage(partner) {
      return (
        "https://playda.s3.ap-south-1.amazonaws.com/assets/brands/" +
        partner +
        ".png"
      );
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100dvh;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.rotateLuck {
  transform: rotate(-5.55deg);
  -webkit-transform: rotate(-5.55deg);
  -moz-transform: rotate(-5.55deg);
  -ms-transform: rotate(-5.55deg);
  -o-transform: rotate(-5.55deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.winnerText {
  min-height: 2.5rem;
}
.rules-text {
  min-height: 2.5rem;
}
.rule-text {
  font-size: 0.8rem;
}
#webpushr-bell-optin {
  display: block !important;
}
.ruleNumber {
  min-width: 2.25rem;
}
.ruleText {
  font-size: 0.8rem;
}
</style>
