<template>
  <div class="wrapper bg-white">
    <div
      v-if="started && gameData.game_details"
      class="grid place-items-center pt-10"
    >
      <div class="flex relative">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/miror/games/logos/true-false.webp"
          alt="Miror - True or False"
          width="295"
          height="85"
        />
      </div>
      <Counter class="mt-4" :score="score" :displayTime="displayTime" />
      <div class="flex gap-2 mt-4">
        <div
          class="tf-container bg-mirorColors-pink rounded-2xl grid place-items-center content-center p-2 relative text-white"
        >
          <p class="font-InterSemiBold text-lg text-center mb-2">
            {{ gameData.game_details.statements[currentSetIndex].statement }}
          </p>
          <div
            id="answerOverlay"
            class="absolute top-0 left-0 bg-black bg-opacity-70 w-full h-full rounded-2xl hidden"
          >
            <div class="flex w-full h-full items-center content-center">
              <lottie-animation
                id="correct"
                path="lotties/answer-correct.json"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                :height="60"
                class="hidden"
              />
              <lottie-animation
                id="wrong"
                path="lotties/answer-wrong.json"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                :height="60"
                class="hidden"
              />
            </div>
          </div>
          <div id="buttons" class="absolute -bottom-8">
            <div class="flex gap-12">
              <button
                id="true"
                @click.prevent="setStatus(true)"
                :class="{ 'disable-click': isClickDisabled }"
                class="focus:outline-none"
              >
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/miror/games/assets/thumbs-up.webp"
                  width="79"
                  height="75"
                />
              </button>
              <button
                id="false"
                @click.prevent="setStatus(false)"
                :class="{ 'disable-click': isClickDisabled }"
                class="focus:outline-none"
              >
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/miror/games/assets/thumbs-down.webp"
                  width="79"
                  height="75"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TimeElapsed ref="timeElapsedRef" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Counter from "@/components/mirorgames/elements/ScoreCounter.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import TimeElapsed from "@/components/elements/TimeElapsed.vue";

export default {
  name: "TrueOrFalse",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  components: { Counter, TimeElapsed, LottieAnimation },
  data() {
    return {
      started: false,
      totalIndex: 0,
      initialTime: {
        sec: 0,
        minute: 1,
      },
      displayTime: {
        sec: 0,
        minute: 1,
      },
      answerStartTime: {
        sec: 0,
        minute: 1,
      },
      totalTimeInMilliseconds: 60 * 1000,
      currentSetIndex: 0,
      score: 0,
      totalTimeTaken: 0,
      userAnswers: [],
      isClickDisabled: false,
    };
  },
  created() {
    this.totalIndex = this.gameData.game_details.statements.length;
    this.startGame();
  },
  mounted() {},
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    startGame() {
      this.started = true;
      this.countDown();
    },
    setStatus(truefalse) {
      this.isClickDisabled = true;
      let answer = {
        set: this.gameData.game_details.statements[this.currentSetIndex].slug,
        answer: truefalse,
        time_taken: 0,
      };
      var timeTaken = this.calculateTimeTakenForAnswer();
      if (!timeTaken) {
        timeTaken = 1000;
      }
      if (
        this.gameData.game_details.statements[this.currentSetIndex]
          .truefalse === truefalse
      ) {
        document.querySelector("#answerOverlay").classList.remove("hidden");
        document.querySelector("#correct").classList.remove("hidden");
        answer.is_correct = true;
        this.score += 1;
        this.totalTimeTaken += timeTaken;
        answer.time_taken = timeTaken;
      } else {
        document.querySelector("#answerOverlay").classList.remove("hidden");
        document.querySelector("#wrong").classList.remove("hidden");
        answer.is_correct = false;
        answer.time_taken = timeTaken;
      }
      this.userAnswers.push(answer);
      if (this.currentSetIndex + 1 === this.totalIndex) {
        this.totalTime += this.timer;
        this.submitScore();
      }
      setTimeout(() => {
        this.currentSetIndex += 1;
        document.querySelector("#answerOverlay").classList.add("hidden");
        document.querySelector("#correct").classList.add("hidden");
        document.querySelector("#wrong").classList.add("hidden");
        this.isClickDisabled = false;
      }, 1000);
    },
    countDown() {
      this.timer = setInterval(() => {
        if (this.displayTime.minute == 0 && this.displayTime.sec == 0) {
          this.submitScore();
        } else {
          if (this.displayTime.sec == 0) {
            this.displayTime.sec = 59;
            this.displayTime.minute--;
          } else {
            this.displayTime.sec--;
          }
        }
      }, 1000);
    },
    submitScore() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      ApiService.post(apiResource.saveTrueFalseScore, {
        business_slug: this.$route.params.business,
        time: this.totalTimeTaken.toString(),
        score: this.score.toString(),
        answers: this.userAnswers,
        schedule_id: this.gameData.schedule_id,
        game_session_id: this.gameData.game_session_id,
        timezone: timezone,
      })
        .then(() => {
          const url = new URL(window.location.href);
          url.searchParams.set("redirect", "gameover");
          url.searchParams.set(
            "game_session_id",
            this.gameData.game_session_id
          );
          window.location.href = url.toString();
        })
        .catch(() => {
          this.$refs.timeElapsedRef.startTimer();
        });
    },
    calculateTimeTakenForAnswer() {
      const diffMilliseconds =
        (this.answerStartTime.minute - this.displayTime.minute) * 60000 +
        (this.answerStartTime.sec - this.displayTime.sec) * 1000;
      this.answerStartTime = { ...this.displayTime };
      return diffMilliseconds;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100dvh;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.tf-container {
  width: 20rem;
  height: 20rem;
}
.statement-box {
  background-color: #ff9564;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
}
.correct-bg {
  background-color: #daf559;
}
.incorrect-bg {
  background-color: #ff74c7;
}
@media (min-width: 410px) {
  .tf-container {
    width: 22rem;
    height: 22rem;
  }
}
.disable-click {
  pointer-events: none;
}
</style>
