<template>
  <div class="wrapper bg-nColorBlackishBG">
    <div
      v-if="started && gameData.game_details"
      class="grid top-0 left-0 w-full items-start pt-10 place-items-center"
    >
      <div
        class="font-ZuumeSemiBold text-5xl text-nColorOrange uppercase flex relative"
      >
        <p class="relative">
          <span>
            <span>Lyric Ling</span>
            <div class="inline-block w-6">
              <p class="rotated-alphabet absolute -top-4 ml-0.5">o</p>
            </div>
          </span>
        </p>
      </div>
      <Counter
        class="relative my-4"
        :score="score"
        :displayTime="displayTime"
        :background="'orange'"
        :textcolor="'black'"
        :showIcons="false"
      />
      <div class="grid gap-2 mt-2">
        <div
          class="bg-nColorContentGray rounded-2xl text-nColorYellow py-10 relative mb-4"
        >
          <div class="absolute -top-6 -left-2">
            <img
              :src="require('@/assets/images/saxophone.png')"
              class="w-2/3"
            />
          </div>
          <div class="absolute -bottom-4 right-0">
            <img :src="require('@/assets/images/drum.png')" class="w-2/3" />
          </div>
          <div class="emotes text-center">
            <h1
              class="text-xl px-2 font-InterSemiBold h-52 flex items-center justify-center"
            >
              {{ gameData.game_details.lyrics[currentSetIndex].lyrics }}
            </h1>
          </div>
        </div>
        <div
          class="lyric-lingo-container rounded-2xl grid grid-cols-2 gap-2 place-items-center content-start pt-2 relative"
        >
          <div
            id="option1"
            @click.prevent="selectLyrics('option1')"
            :class="{ 'disable-click': isClickDisabled }"
            class="lyric-box shadow-yellow-right-small flex justify-between items-center"
          >
            A.
            {{ gameData.game_details.lyrics[currentSetIndex].option1 }}
            <div id="option1-correct" class="hidden">
              <img :src="require('@/assets/images/correct.png')" />
            </div>
            <div id="option1-incorrect" class="hidden">
              <img :src="require('@/assets/images/incorrect.png')" />
            </div>
          </div>
          <div
            id="option2"
            @click.prevent="selectLyrics('option2')"
            :class="{ 'disable-click': isClickDisabled }"
            class="lyric-box shadow-yellow-right-small flex justify-between items-center"
          >
            B.
            {{ gameData.game_details.lyrics[currentSetIndex].option2 }}
            <div id="option2-correct" class="hidden">
              <img :src="require('@/assets/images/correct.png')" />
            </div>
            <div id="option2-incorrect" class="hidden">
              <img :src="require('@/assets/images/incorrect.png')" />
            </div>
          </div>
          <div
            id="option3"
            @click.prevent="selectLyrics('option3')"
            :class="{ 'disable-click': isClickDisabled }"
            class="lyric-box shadow-yellow-right-small flex justify-between items-center"
          >
            C.
            {{ gameData.game_details.lyrics[currentSetIndex].option3 }}
            <div id="option3-correct" class="hidden">
              <img :src="require('@/assets/images/correct.png')" />
            </div>
            <div id="option3-incorrect" class="hidden">
              <img :src="require('@/assets/images/incorrect.png')" />
            </div>
          </div>
          <div
            id="option4"
            @click.prevent="selectLyrics('option4')"
            :class="{ 'disable-click': isClickDisabled }"
            class="lyric-box shadow-yellow-right-small flex justify-between items-center"
          >
            D.
            {{ gameData.game_details.lyrics[currentSetIndex].option4 }}
            <div id="option4-correct" class="hidden">
              <img :src="require('@/assets/images/correct.png')" />
            </div>
            <div id="option4-incorrect" class="hidden">
              <img :src="require('@/assets/images/incorrect.png')" />
            </div>
          </div>
          <div class="text-nColorDarkGray font-InterExtraBold text-xl">
            <div class="correct hidden">Yay! you got it right!</div>
            <div class="incorrect hidden">Oops! you got that wrong!</div>
          </div>
        </div>
      </div>
    </div>
    <TimeElapsed ref="timeElapsedRef" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Counter from "@/components/elements/ScoreCounter.vue";
import TimeElapsed from "@/components/elements/TimeElapsed.vue";

export default {
  name: "LyricLingo",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  components: { Counter, TimeElapsed },
  data() {
    return {
      started: false,
      totalIndex: 0,
      initialTime: {
        sec: 0,
        minute: 2,
      },
      displayTime: {
        sec: 0,
        minute: 2,
      },
      answerStartTime: {
        sec: 0,
        minute: 2,
      },
      totalTimeInMilliseconds: 120 * 1000,
      currentSetIndex: 0,
      score: 0,
      totalTimeTaken: 0,
      userAnswers: [],
      isClickDisabled: false,
    };
  },
  created() {
    this.totalIndex = this.gameData.game_details.lyrics.length;
    this.startGame();
  },
  mounted() {},
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    startGame() {
      this.started = true;
      this.countDown();
    },
    selectLyrics(lyric) {
      this.isClickDisabled = true;
      let answer = {
        set: this.gameData.game_details.lyrics[this.currentSetIndex].slug,
        answer: lyric,
        time_taken: 0,
      };
      var timeTaken = this.calculateTimeTakenForAnswer();
      if (!timeTaken) {
        timeTaken = 1000;
      }
      if (
        this.gameData.game_details.lyrics[this.currentSetIndex]
          .correct_answer === lyric
      ) {
        document.getElementById(lyric).style.backgroundColor = "#57c754";
        document.getElementById(lyric).style.color = "#000000";
        answer.is_correct = true;
        document
          .querySelector("#" + lyric + "-correct")
          .classList.remove("hidden");
        this.score += 1;
        this.totalTimeTaken += timeTaken;
        answer.time_taken = timeTaken;
      } else {
        document.getElementById(lyric).style.backgroundColor = "#D999B9";
        document.getElementById(lyric).style.color = "#000000";
        answer.is_correct = false;
        document
          .querySelector("#" + lyric + "-incorrect")
          .classList.remove("hidden");

        answer.time_taken = timeTaken;
      }
      this.userAnswers.push(answer);
      if (this.currentSetIndex + 1 === this.totalIndex) {
        this.submitScore();
      }
      setTimeout(() => {
        document
          .querySelector("#" + lyric + "-correct")
          .classList.add("hidden");
        document
          .querySelector("#" + lyric + "-incorrect")
          .classList.add("hidden");
        this.currentSetIndex += 1;
        document.getElementById(lyric).style.backgroundColor = "#1A1718";
        document.getElementById(lyric).style.color = "#FFCB3B";
        this.isClickDisabled = false;
      }, 1000);
    },
    countDown() {
      this.timer = setInterval(() => {
        if (this.displayTime.minute == 0 && this.displayTime.sec == 0) {
          this.submitScore();
        } else {
          if (this.displayTime.sec == 0) {
            this.displayTime.sec = 59;
            this.displayTime.minute--;
          } else {
            this.displayTime.sec--;
          }
        }
      }, 1000);
    },
    submitScore() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      ApiService.post(apiResource.saveLyricLingoScore, {
        business_slug: this.$route.params.business,
        time: this.totalTimeTaken.toString(),
        score: this.score.toString(),
        answers: this.userAnswers,
        schedule_id: this.gameData.schedule_id,
        game_session_id: this.gameData.game_session_id,
        timezone: timezone,
      })
        .then(() => {
          const url = new URL(window.location.href);
          url.searchParams.set("redirect", "gameover");
          url.searchParams.set(
            "game_session_id",
            this.gameData.game_session_id
          );
          window.location.href = url.toString();
        })
        .catch(() => {
          this.$refs.timeElapsedRef.startTimer();
        });
    },
    calculateTimeTakenForAnswer() {
      const diffMilliseconds =
        (this.answerStartTime.minute - this.displayTime.minute) * 60000 +
        (this.answerStartTime.sec - this.displayTime.sec) * 1000;
      this.answerStartTime = { ...this.displayTime };
      return diffMilliseconds;
    },
  },
};
</script>

<style scoped lang="postcss">
.wrapper {
  height: 100dvh;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.lyric-lingo-container {
  width: 20rem;
  height: 8rem;
}
.correct-bg {
  background-color: #daf559;
}
.incorrect-bg {
  background-color: #ff74c7;
}
.lyric-lingo-lyric {
  width: 20rem;
}
.emotes {
  max-width: 20rem;
}
@media (min-width: 410px) {
  .lyric-lingo-container {
    width: 22rem;
    height: 24rem;
  }
  .lyric-lingo-lyric {
    width: 22rem;
  }
  .emotes {
    max-width: 22rem;
  }
}
.lyric-box {
  @apply bg-nColorBlackishBG border border-nColorYellow rounded-lg w-full text-nColorYellow py-2.5 text-sm font-InterMedium focus:outline-none text-left pl-4 pr-2;
}
.disable-click {
  pointer-events: none;
}
</style>
