<template>
  <div class="wrapper bg-white" v-if="lobbyDetails">
    <div class="grid gap-2 lobbyHeader" v-if="!lobbyDetails.no_games">
      <div class="flex justify-center">
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/miror/miror_logo.webp"
          width="165"
          height="50"
        />
      </div>
      <div
        class="text-mirorColors-pink text-3xl tracking-widest text-center font-MontserratRegular"
      >
        ARCADE
      </div>
      <div
        class="text-black lobbySubText text-center font-MontserratAlternatesSemiBold"
      >
        Play your way to a Healthier you!
      </div>
    </div>
    <NoExpsToday v-if="lobbyDetails.no_games" />
    <AllExpsDone v-if="!lobbyDetails.leaderboard && lobbyDetails.games_done" />
    <div class="bg-white" v-else>
      <div
        class="flex flex-col place-items-center"
        v-if="lobbyDetails && !showGames"
      >
        <div class="w-full px-4">
          <div class="border-t border-b border-mirorColors-pink lobbyPoints">
            <div class="flex">
              <div class="w-20 p-2">
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/miror/lobby_hand_time.webp"
                  width="46"
                  height="45"
                />
              </div>
              <div class="border-l border-mirorColors-pink my-2.5"></div>
              <div class="font-InterRegular text-xs p-2 w-full">
                Take a moment for yourself with games that promote stress
                reduction, relaxation & impoved sleep
              </div>
            </div>
            <div class="flex mt-2">
              <div class="w-20 p-2">
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/miror/lobby_hand_phone.webp"
                  width="38"
                  height="50"
                />
              </div>
              <div class="border-l border-mirorColors-purple my-2.5"></div>
              <div class="font-InterRegular text-xs p-2 w-full">
                Discover games that boost your Mood, Focus & Memory function,
                all while having fun.
              </div>
            </div>
          </div>
        </div>
        <div
          class="font-MontserratAlternatesSemiBold text-mirorColors-pink pt-2 lobbyTopicText"
        >
          Click On a Topic below to get started!
        </div>
        <div class="w-full lobbyCategories" v-if="lobbyDetails.has_category">
          <div class="grid grid-cols-2">
            <div
              v-for="cat in lobbyDetails.games"
              :key="cat"
              @click="showGamesForCategory(cat)"
            >
              <div>
                <img :src="cat.category_icon" class="w-full" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 mt-4" v-else>
          <div
            v-for="game in lobbyDetails.games"
            :key="game"
            :class="{
              'bg-black text-nColorYellow border border-nColorYellow':
                lobbyDetails.next_game,
              'bg-nColorYellow border border-black':
                lobbyDetails.current_game ||
                lobbyDetails.leaderboard ||
                lobbyDetails.games_done,
            }"
            class="mb-2 p-2 rounded-2xl shadow-orange-right"
            @click.prevent="
              (lobbyDetails.current_game ||
                lobbyDetails.leaderboard ||
                lobbyDetails.games_done) &&
                goToGame(game.slug, game.schedule_id)
            "
          >
            <div class="flex justify-between items-center">
              <div class="flex gap-2 items-center">
                <div class="flex justify-center items-center">
                  <img :src="game.icon" class="w-10" />
                </div>
                <div class="font-ZuumeSemiBold text-xl">
                  {{ game.name }}
                </div>
              </div>
              <div v-if="lobbyDetails.current_game">
                <img
                  :src="require('@/assets/images/right-arrow.svg')"
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full px-4" v-if="showGames">
        <div class="flex items-center w-full py-2">
          <div
            class="text-mirorColors-pink font-MontserratSemiBold text-2xl flex items-center gap-2"
          >
            <div>
              <img
                :src="require('@/assets/images/navigate-back-miror.svg')"
                @click="hideGamesAndShowCategories()"
              />
            </div>
            <div>
              {{ selectedCategory.category_name }}
            </div>
          </div>
        </div>
        <div
          v-for="game in selectedCategoryGames"
          :key="game"
          class="mb-2 p-2 rounded-2xl bg-white text-nColorYellow border border-mirorColors-pink"
          @click.prevent="
            (lobbyDetails.current_game ||
              lobbyDetails.leaderboard ||
              lobbyDetails.games_done) &&
              goToGame(game.slug, game.schedule_id)
          "
        >
          <div class="flex justify-between items-center">
            <div class="flex gap-2 items-center">
              <div class="flex justify-center items-center">
                <img :src="game.icon" class="w-10" />
              </div>
              <div class="font-MontserratSemiBold text-mirorColors-pink">
                {{ game.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import NoExpsToday from "@/components/emptystates/NoExpsToday.vue";
import AllExpsDone from "@/components/emptystates/AllExpsDone.vue";

export default {
  name: "Lobby",
  components: { NoExpsToday, AllExpsDone },
  data() {
    return {
      businessSlug: this.$store.getters.businessSlug,
      lobbyDetails: null,
      game: null,
      showGames: false,
      selectedCategory: null,
      selectedCategoryGames: [],
    };
  },
  created() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    ApiService.get(apiResource.miror.getLobbyDetails, {
      business_slug: this.$route.params.business,
      timezone: timezone,
    }).then((data) => {
      this.lobbyDetails = data.data.data;

      if (this.lobbyDetails.game) {
        this.game = this.lobbyDetails.game;
      }
    });
  },
  mounted() {},
  computed: {},
  methods: {
    showGamesForCategory(category) {
      this.selectedCategory = category;
      this.setGamesForCategory(category);
      this.showGames = true;
    },
    hideGamesAndShowCategories() {
      this.selectedCategory = null;
      this.selectedCategoryGames = null;
      this.showGames = false;
    },
    setGamesForCategory(category) {
      for (const cat of this.lobbyDetails.games) {
        if (cat.category_slug === category.category_slug) {
          this.selectedCategoryGames = cat.games;
          return;
        }
      }
      return;
    },
    goToGame(game, scheduleId) {
      this.$router.push({
        name: "Game",
        params: {
          business: this.$route.params.business,
          schedule_id: scheduleId,
          game: game,
        },
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100dvh;
}
.winnerText {
  min-height: 2.5rem;
}
.rules-text {
  min-height: 2.5rem;
}
.rule-text {
  font-size: 0.8rem;
}
#webpushr-bell-optin {
  display: block !important;
}
.ruleNumber {
  min-width: 2.25rem;
}
.ruleText {
  font-size: 0.8rem;
}
.lobbyTopicText,
.lobbySubText {
  @apply text-lg;
}
.lobbyCategories {
  @apply mt-4;
}
.lobbyHeader {
  @apply py-4;
}
.lobbyPoints {
  @apply p-2;
}
@media (max-width: 376px) {
  .lobbyTopicText,
  .lobbySubText {
    @apply text-sm;
  }
  .lobbyCategories {
    @apply mt-1;
  }
  .lobbyHeader {
    @apply pt-3 pb-1;
  }
}
</style>
